<template>
  <div class="list">
    <div class="bgColor"></div>
    <van-nav-bar title="申领记录" left-arrow @click-left="onClickLeft" />
     <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" @load="onLoad">
    <div class="list-item" v-for="(item, index) in lists" :key="index">
      <div class="name">{{item.name}}（{{item.householdRelation.name}}）</div>
      <div class="time">地址：{{item.building.address}}</div>
      <div class="btn" @click="toInfo(item.id)">查看详情</div>
    </div>
    </van-list>
      </van-pull-refresh>
      <div class="zan" v-if="lists.length==0">暂无记录</div>
  </div>
</template>
<script>
import { Icon, NavBar, Loading, PullRefresh, List } from "vant";
import { myList } from "@/api/apiData";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
       [Loading.name]: Loading,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
  },
  data() {
    return {
      page: 0, //当前页码
      offset: 8, //当前页面条目数
      totalPages: 1, //总页码
      lists: [], //文章数组
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
   mounted() {
    this.getList();
  },
  methods: {
     getList() {
      var token = localStorage.getItem("token");
      // bargainList({ token: token }).then((res) => {});
      var that = this;
      var page = that.page + 1;
      var lists = that.lists;
      var totalPages = that.totalPages;
      var offset = that.offset;
      let obj = {
        page: page,
        size: offset,
        token: token,
      };
      if (totalPages >= page) {
        myList(obj).then((res) => {
          if (res.code == 1) {
            that.page = page;
            that.lists = lists.concat(res.data.list);
            that.totalPages = Math.ceil(
              Number(res.data.total) / res.data.list.length
            );
            if (res.data.list.length == 0) {
              //数据请求成功 但是无数据
              that.finished = true;
              that.loading = false;
              return false;
            }
            if (that.lists.length >= lists.length) {
              // 数据请求成功后  停止加载状态
              setTimeout(() => {
                that.loading = false;
              }, 1500);
            }
          }
        });
      } else {
        // 全部数据加载完毕
        that.finished = true;
      }
    },
    // 上拉记载
    onLoad() {
      // var logins = localStorage.getItem("logins");
      // if (logins == 2) {
      //   setTimeout(() => {
      //     this.getList();
      //   }, 500);
      // }
      setTimeout(() => {
        this.getList();
      }, 500);
    },
    // 下拉刷新
    onRefresh() {
      var that = this;
      that.lists = [];
      that.page = 0;
      that.offset = 12;
      that.totalPages = 1;
      that.loading = false;
      that.finished = false;
      that.getList();
      setTimeout(() => {
        that.refreshing = false;
      }, 1500);
    },
    onClickLeft() {
      this.$router.push("/index");
    },
    toInfo(id){
      this.$router.push({
        path:"/info",
        query:{
            id:id
        }
      });
    },
  },
};
</script>
<style scoped>
.list-item {
  background: white;
  margin: 20px;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}
.name {
  font-weight: bold;
  font-size: 16px;
}
.time {
  color: gray;
  font-size: 12px;
  margin-top: 10px;
}
.btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #0038cf;
  color: white;
  font-size: 12px;
  padding: 3px 5px;
  border-radius: 5px;
}
</style>